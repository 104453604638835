import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => (
  {
    tournamentBox:
    {
      marginTop: theme.spacing(3),
    }
  }
))

export default useStyle
import "./NotFound.css";
import React from "react";

function template() {
  return (
    <div className="not-found">
      <h1>404 - Not Found</h1>
      <h4>Je sais pas ou tu vas bonhomme mais y'a rien a voir ici</h4>
    </div>
  );
};

export default template;
